/**
 * 面授创建API
 */
import { fetch, post, del } from 'api/base/axios'

const URL = {
  filterUrl: '/curriculum/video/list/',
  filterAdminUrl: '/curriculum/video/admin/list/',
  delUrl: '/curriculum/video/delete/',
  playUrl: '/curriculum/video/preview/',
  saveRecordUrl: '/curriculum/video/record/',
  getRecordUrl: '/curriculum/video/get_record/',
  getHLSTokenUrl: '/curriculum/video/get_hls_token/'
}

/**
 * 查询用户
 * @param data
 * @returns {Promise}
 */
export function listVideo (data) {
  return post(URL.filterUrl, data)
}

export function listAdminVideo(data){
  return fetch(URL.filterAdminUrl, data)
}

export function delVideo (uuid) {
  return del(URL.delUrl + uuid + '/')
}

export function playVideo(media_id, params) {
  return fetch(URL.playUrl + media_id + '/', params)
}

export function saveVideoRecord(data) {
  return post(URL.saveRecordUrl, data)
}

export function getVideoRecord(data) {
  return fetch(URL.getRecordUrl, data)
}


export function getHLSToken(video_uuid, cur_uuid) {
  return fetch(URL.getHLSTokenUrl, {
    'video_uuid': video_uuid,
    'cur_uuid': cur_uuid
  })
}
