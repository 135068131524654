/**
 * Created by syk on 21-1-4.
 */
const aliplayer_skinlayout = [
    {
        "name": "bigPlayButton",
        "align": "blabs",
        "x": 30,
        "y": 80
    },
    {
        "name": "H5Loading",
        "align": "cc"
    },
    {
        "name": "errorDisplay",
        "align": "tlabs",
        "x": 0,
        "y": 0
    },
    {
        "name": "infoDisplay"
    },
    {
        "name": "tooltip",
        "align": "blabs",
        "x": 0,
        "y": 56
    },
    {
        "name": "thumbnail"
    },
    {
        "name": "controlBar",
        "align": "blabs",
        "x": 0,
        "y": 0,
        "children": [
            {
                "name": "progress",
                "align": "blabs",
                "x": 0,
                "y": 44
            },
            {
                "name": "playButton",
                "align": "tl",
                "x": 15,
                "y": 12
            },
            {
                "name": "timeDisplay",
                "align": "tl",
                "x": 10,
                "y": 7
            },
            {
                "name": "fullScreenButton",
                "align": "tr",
                "x": 10,
                "y": 12
            },
            {
                "name": "volume",
                "align": "tr",
                "x": 5,
                "y": 10
            }
        ]
    }
]

export default aliplayer_skinlayout
