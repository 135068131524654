<template>
  <iframe :src="outerLink" v-if="playType == 'outer_iframe'" scrolling="no" width="100%" height="100%"></iframe>
  <div v-else style="width: 100%; height: 100%;">
    <div v-if="videoError" class="video-err">
      <span>视频处理中，请稍后重试</span>
    </div>
    <div id="J_prismPlayer" class="prism-player" v-if="showAliPlayer" style="width: 100%; height:100%;"></div>
    <video id="player-container-id" v-else-if="showTcPlayer" v-for="l in tcList" style="width: 100%; height:100%;" preload="auto" playsinline webkit-playsinline></video>
  </div>
</template>

<script>
import { showMessage } from 'api/base/mobile/message'
import { showMessageBox } from 'api/base/message'
import { listVideo, playVideo, saveVideoRecord, getVideoRecord, getHLSToken} from "api/course/video/list"
import aliplayer_skinlayout from "api/course/video/aliplayer_skinlayout"
import { v4 as uuidv4 } from 'uuid'
import AliPlayerComponent from 'api/course/video/aliplayercomponents-1.0.5.min'
export default {
  name: "VideoPlay",
  props: {
    video_trial_time: Number,
    canStudy: Boolean,
    video: Object,
    tryOver: Boolean,
    record: {
      type: Boolean,
      default: false
    },
    cur_uuid: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      media_id : '',
      video_id: '',
      showAliPlayer: false,
      showTcPlayer: false,
      playType: '',
      outerLink: '',
      timer: '',
      session_token: uuidv4(),
      player: '',
      player_quality: null,
      hls_play_list: {},
      lastPlayTime: 0,
      videoPlayed: false,
      tcList: [1],
      playAuth: {},
      videoError: false,
      loaded: false
    }
  },
  created() {
    const s = document.createElement('script');
    s.type = 'text/JavaScript';
    s.src = 'https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js';
    document.body.appendChild(s);

    const s4 = document.createElement('script');
    s4.type = 'text/JavaScript';
    s4.src = 'https://g.alicdn.com/de/prismplayer/2.8.2/aliplayer-h5-min.js';
    document.body.appendChild(s4);

    const s1 = document.createElement('script');
    s1.type = 'text/JavaScript';
    s1.src = '//imgcache.qq.com/open/qcloud/video/tcplayer/libs/hls.min.0.12.4.js';
    const s2 = document.createElement('script');
    s2.type = 'text/JavaScript';
    s2.src = '//imgcache.qq.com/open/qcloud/video/tcplayer/libs/dash.all.min.2.9.3.js';
    const s3 = document.createElement('script');
    s3.type = 'text/JavaScript';
    s3.src = '//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js';
    document.body.appendChild(s1)
    document.body.appendChild(s2)
    document.body.appendChild(s3)
    this.loaded = true
  },
  mounted() {
    let that = this
    console.log(that.loaded)
    let timer = setInterval(function (){
      if(that.loaded){
        that.prepareData(that.video)
        clearInterval(timer)
      }
    }, 200)

  },
  methods: {
    prepareData(video) {
      this.showAliPlayer = false
      this.showTcPlayer = false
      this.video_id = video.id
      this.media_id = video.cdn_resource.media_id
      this.playType = video.cdn_resource.play_type
      this.outerLink = video.cdn_resource.oss_path
      if(this.timer){
        clearInterval(this.timer)
      }
      if(this.playType != 'outer_iframe'){
        if(video.cdn_resource.vendor == 1){
          this.showAliPlayer = true
        }
        else {
          this.showTcPlayer = true
        }
        this.getVideo()
      }
      else if(this.record){
        this.saveRecord(0, 0)
      }
    },
    getVideo(){
      if(!this.canStudy && this.video_trial_time == 0){
        return false
      }
      var that = this
      listVideo({'video_id': that.video_id})
        .then(res => {
          if(res.total > 0){
            var video = res.data[0]
            if(video.cdn_resource.state != 8){
              that.videoError = true
              return false
            }
            else {
              that.videoError = false
              if(video.cdn_resource.vendor == 1){
                that.showAliPlayer = true
              }
              else {
                that.showTcPlayer = true
              }
              that._getPlayAuth(that.video_id)
            }
          }
          else {
            this.showMessageHandler('获取视频失败')
          }
        })
    },
    // 获取视频鉴权地址
    _getPlayAuth (val) {
      const that = this
      let params = {'video_id': val}

      if (that.cur_uuid != '') {
        params['cur_uuid'] = that.cur_uuid
      }

      if(that.record){
        params['need_record'] = true
      }
      playVideo(that.media_id, params)
        .then(res => {
          var cdn_resource = res.video_info.cdn_resource

          try{
            if (cdn_resource.vendor === 1) {
              if(cdn_resource.play_type === 'm3u8' || cdn_resource.play_type === 'm3u8_free'){
                let source = {
                  'FD': res.play_auth.url_arr,
                  'LD': res.play_auth.url_arr,
                  'SD': res.play_auth.url_arr,
                  'HD': res.play_auth.url_arr,
                  // 'OD': res.play_auth.url_arr,
                  // '2K': res.play_auth.url_arr,
                  // '4K': res.play_auth.url_arr
                }
                that.player = new Aliplayer({
                  id: "J_prismPlayer", // 容器id
                  source: JSON.stringify(source),
                  cover: "",  //播放器封面图
                  preload: true,
                  autoplay: true,
                  showBarTime:"3000",
                  language:'zh-cn',
                  skinLayout: aliplayer_skinlayout,
                  components: [
                    {
                      name: 'RateComponent',
                      type: window.AliPlayerComponent.RateComponent
                    }, {
                      name: 'QualityComponent',
                      type: window.AliPlayerComponent.QualityComponent
                    }
                  ]
                },function(player){
                  console.log("播放器创建了。");
                })
              }
              else if (cdn_resource.play_type === 'h5_hls_std'){

                if (cdn_resource.state !== 8) {
                  that.videoError = true
                  return false
                }
                that.videoError = false
                that.showAliPlayer = true
                that.playAuth = res.play_auth.play_auth;
                that.hls_play_list = res.no_token_play_list;
                that.player = new Aliplayer({
                    id: "J_prismPlayer", // 容器id
                    source: JSON.stringify(res.play_list),
                    useH5Prism: true,
                    isLive: false,//是不是直播
                    preload: true,//播放器自动加载
                    extraInfo: { //定制型接口参数
                      crossOrigin: "anonymous"
                    },
                    cover: "",  //播放器封面图
                    showBarTime: "3000",
                    encryptType: 1,
                    autoplay: true,      // 是否自动播放
                    authPlayDelay: 10,
                    skinLayout: aliplayer_skinlayout,
                    components: [
                      {
                        name: 'RateComponent',
                        type: window.AliPlayerComponent.RateComponent
                      }, {
                        name: 'QualityComponent',
                        type: window.AliPlayerComponent.QualityComponent
                      }
                    ]
                  }, function (player) {
                    console.log("播放器创建了。");
                  }
                )

                that.player.on('canplay', function (params) {

                  const quality_dict = {
                    'FD': "流畅",
                    'LD': "标清",
                    'SD': '高清',
                    'HD': "超清",
                    'OD': '原画',
                    '2K': '2K',
                    '4K': '4K'
                  }

                  let quality_component = that.player.getComponent('QualityComponent');

                  let current_quality = quality_component.definition;

                  if (current_quality === '') {
                    current_quality = that.player.getCurrentQuality()['definition'];
                  }

                  let origin_quality_text = quality_dict[current_quality];
                  let quality_url = that.hls_play_list[current_quality];

                  console.log('canplay',
                    current_quality,
                    that.player_quality,
                    current_quality !== that.player_quality )

                  if (current_quality !== that.player_quality) {

                    that.player_quality = current_quality;

                    console.log('[Definition Change]', current_quality, origin_quality_text, quality_url);

                    that.player.getComponent('QualityComponent').setCurrentQuality(
                      origin_quality_text,
                      current_quality
                    )

                    that.addHLSTokenURL(quality_url);

                  }
                })


              }else {
                if(cdn_resource.state != 8){
                  that.videoError = true
                  return false
                }
                that.videoError = false
                that.showAliPlayer = true
                that.playAuth = res.play_auth.play_auth
                that.player = new Aliplayer({
                  id: "J_prismPlayer", // 容器id
                  vid: res.play_auth.play_auth.VideoMeta.VideoId,
                  playauth: res.play_auth.play_auth.PlayAuth,
                  useH5Prism: true,
                  isLive:false,//是不是直播
                  preload:true,//播放器自动加载
                  extraInfo:{ //定制型接口参数
                    crossOrigin:"anonymous"
                  },
                  cover: "",  //播放器封面图
                  showBarTime:"3000",
                  encryptType:1,
                  autoplay: true,      // 是否自动播放
                  authPlayDelay: 10,
                  skinLayout: aliplayer_skinlayout,
                  components: [
                    {
                      name: 'RateComponent',
                      type: window.AliPlayerComponent.RateComponent
                    }, {
                      name: 'QualityComponent',
                      type: window.AliPlayerComponent.QualityComponent
                    }
                  ]
                },function(player){
                    console.log("播放器创建了。");
                  }
                )
              }
            }
            else {
              if(cdn_resource.state != 8){
                that.videoError = true
                return false
              }
              that.player = TCPlayer('player-container-id', { // player-container-id 为播放器容器ID，必须与html中一致
                fileID: res.file_id, // 请传入需要播放的视频filID 必须
                appID: '1300068638' // 请传入点播账号的appID 必须
              });
            }
            that.controlVideo()

            if(that.record){
              let seeked = false
              try{
                that.player.on('canplaythrough',function  (e) {
                  if(!seeked) {
                    seeked = true;
                    that.player.seek(res.play_time)
                  }
                });
                // that.player.seek(res.play_time);
              }catch (e) {
                try{
                  that.player.currentTime(res.play_time);
                }catch (e2) {
                  console.log(e2);
                }
              }
            }
            that.controlPlay()
            that.saveRecord(0, 60)
          }
          catch (e) {
            console.log(e);
            that.videoError = true
            this.showMessageHandler(e)
          }
        })
    },

    async addHLSTokenURL(quality_url) {

      if (quality_url) {

        const that = this;

        that.player.pause();

        await getHLSToken(this.video.uuid, this.cur_uuid).then(result => {

          if (result.succeed) {
            let token_quality_url = `${quality_url}?MtsHlsUriToken=${result.hls_token}`;
            that.player.loadByUrl(token_quality_url,  that.player.getCurrentTime());
            that.player.play();
          } else {
            alert(result.error_msg);
          }

        }).catch(err => {
          console.log(err);
        })

      }
    },

    showMessageHandler(msg) {
      if(this.$route.path.includes('mobile')) {
        showMessage(msg)
      } else {
        showMessageBox(msg)
      }
    },
    controlPlay(){
      if(this.timer){
        clearInterval(this.timer)
      }
      const that = this
      let error = 0
      let index = 0
      that.timer = setInterval(function (){
        if (typeof (that.player) != "undefined") {
          let current_time = 0
          let video_duration = 0
          try {
            // try tencent
            current_time = that.player.currentTime()
            video_duration = that.player.duration()
          } catch (e) {
            current_time = that.player.getCurrentTime()
            video_duration = that.player.getDuration()
          }
          if (!that.canStudy) { //试看的
            if (current_time >= that.video_trial_time) {
              that.player.dispose()
              that.$emit('update:tryOver', true)
              clearInterval(that.timer)
            }
          } else if (that.record) {
            index ++
            if(current_time != that.lastPlayTime && (index % 20 == 0 || current_time >= video_duration)){
              that.lastPlayTime = current_time
              that.saveRecord(current_time, video_duration)
            }
          }
        }
      }, 1000)
    },
    destoryVideoPlayer() {
      if(this.timer){
        clearInterval(this.timer)
      }
      try{
        this.outerLink = ''
        this.player.dispose()
      }catch (e){}
    },
    saveRecord(current_time, video_duration) {
      var data = {
        cur_uuid: this.cur_uuid,
        video_uuid: this.video.uuid,
        play_time: current_time,
        duration: video_duration,
        session_token: this.session_token,
      }
      if(current_time < video_duration){
        data['is_finish'] = 0
      }
      else {
        data['is_finish'] = 1
      }
      saveVideoRecord(data).then(res => {
        console.log('记录视频记录')
      })
    },

    controlVideo() {
      const that = this
      const quality_dict = {
        'FD': "流畅",
        'LD': "标清",
        'SD': '高清',
        'HD': "超清",
        'OD': '原画',
        '2K': '2K',
        '4K': '4K'
      }
      let load_quality_text = true
      let is_aliyun = this.showAliPlayer
      that.player.on('play', function () {
        that.videoPlayed = true
        if (is_aliyun && load_quality_text) {
          load_quality_text = false
        }
      });

      that.player.on('pause', function () {
        that.videoPlayed = false
      });

      if(is_aliyun){
        document.getElementsByTagName('video')[0].onclick=function (){
          console.log('0000')
          if (that.videoPlayed) {
            that.player.pause()
          } else if (!that.videoPlayed) {
            that.player.play()
          }
        }
      }

      window.addEventListener('keyup',this.handleKeyup)
    },
    handleKeyup(ev) {
      var e = ev || event;
      var is_Matched = false
      let is_aliyun = this.showAliPlayer
      if (e.keyCode == 32) {
        if (this.videoPlayed) {
          this.player.pause()
        } else if (!this.videoPlayed) {
          this.player.play()
        }
        is_Matched = true
      }
      switch (e.keyCode) {
        case 38 : { // arrow up
          is_Matched = true
          if (is_aliyun) {
            this.player.setVolume(this.player.getVolume() + 0.1)
          } else {
            this.player.volume(this.player.volume() + 0.1)
          }
        }
          break
        case 40 : { // arrow down
          is_Matched = true;
          if (is_aliyun) {
            this.player.setVolume(this.player.getVolume() - 0.1)
          } else {
            this.player.volume(this.player.volume() - 0.1)
          }
        }
          break
        case 37 : { // arrow left
          is_Matched = true

          if (is_aliyun) {
            this.player.seek(this.player.getCurrentTime() - 10)
          } else {
            this.player.currentTime(this.player.currentTime() - 10)
          }
        }
          break
        case 39 : { // arrow right
          is_Matched = true
          if (is_aliyun) {
            this.player.seek(this.player.getCurrentTime() + 10)
          } else {
            this.player.currentTime(this.player.currentTime() + 10)
          }
        }
          break
      }
      if (is_Matched) {
        return false
      }
    }
  },
  unmounted() {
    this.destoryVideoPlayer()
  },
  watch: {
    'video' (val) {
      this.destoryVideoPlayer()
      this.videoError = false
      this.prepareData(val)
    },
  }
}
</script>

<style>
@import 'https://g.alicdn.com/de/prismplayer/2.8.2/skins/default/aliplayer-min.css';
@import '//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.css';
</style>

<style lang="scss" scoped>
.prism-player {
  z-index:1;
}
.video-err{
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  span {
    top: 50%;
    position: absolute;
    color: #fff;
    font-size: 20px;
    transform: translateX(-50%);
  }
}
</style>
